<template>
  <div>
    <v-navigation-drawer
      :value="formStatus"
      @input="handleDrawerModelValueUpdate"
      fixed
      temporary
      right
      width="40%"
    >
      <v-card flat>
        <v-card-title class="py-2 pr-3 justify-space-between">
          <h5>
            {{ mode == "new" ? "New Purchase Order" : "Update Purchase Order" }}
          </h5>
          <v-btn icon @click="dismiss">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>

        <v-divider></v-divider>

        <v-form ref="form" :value="validity" lazy-validation>
          <v-card-text>
            <v-row class="flex-column">
              <v-col>
                <h5 class="mb-1">PO Number *</h5>
                <v-text-field
                  v-model="po_number"
                  single-line
                  outlined
                  dense
                  label="PO Number"
                  hide-details="auto"
                  required
                  :rules="poNumberValidity"
                ></v-text-field>
              </v-col>

              <v-col>
                <h5 class="mb-1">Campaign *</h5>
                <v-autocomplete
                  clearable
                  dense
                  outlined
                  label="Campaign Name"
                  :items="campaigns"
                  hide-details
                  return-object
                  item-text="name"
                  variant="solo-filled"
                  :rules="campaignValidity"
                  v-model="campaign"
                ></v-autocomplete
              ></v-col>

              <v-col>
                <h5 class="mb-1">Customer *</h5>
                <v-autocomplete
                  dense
                  outlined
                  clearable
                  hide-details
                  label="Customer"
                  :items="customers"
                  item-text="name"
                  variant="solo-filled"
                  :rules="customerValidity"
                  return-object
                  v-model="customer"
                ></v-autocomplete
              ></v-col>

              <v-col>
                <h5 class="mb-1">PO Upload</h5>
                <v-file-input
                  dense
                  outlined
                  prepend-icon="mdi-file-arrow-up-down-outline"
                  v-model="po_file"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>

      <template #append>
        <v-card>
          <v-card-text>
            <v-btn
              :disabled="loading"
              :loading="loading"
              type="submit"
              @click="submitForm"
              block
              depressed
              color="primary"
              class="mt-2"
              >Submit</v-btn
            >
          </v-card-text>
        </v-card>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    formStatus: {
      type: Boolean,
      required: true,
    },
    purchaseOrder: {
      type: Object,
      required: false,
      default: null,
    },
    mode: {
      type: String,
      required: false,
      default: "new",
    },
  },
  data() {
    return {
      loading: false,
      po_number: null,
      campaign: null,
      customer: null,
      po_file: null,
      customer_image: null,
      poNumberValidity: [(value) => !!value || "PO Number is required"],
      campaignValidity: [(value) => !!value || "Campaign is required"],
      customerValidity: [(value) => !!value || "Customer is required"],
      validity: true,
    }
  },
  mounted() {
    this.getCampaigns()
  },
  watch: {
    purchaseOrder() {
      if (this.purchaseOrder) {
        this.po_number = this.purchaseOrder?.po_number
        this.campaign = this.purchaseOrder?.campaign
        this.customer = this.purchaseOrder?.customer
      }
    },
  },
  methods: {
    async getCampaigns() {
      //Campaigns
      try {
        this.loading = true
        await this.$store.dispatch("dsp/getAllDspCampaigns")
        this.loading = false
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async getPurchaseOrders() {
      try {
        this.loading = true
        await this.$store.dispatch("billing/fetchPOs")
        this.loading = false
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
    dismiss() {
      this.$emit("dismiss")
    },
    handleDrawerModelValueUpdate(value) {
      if (value == false) {
        this.$emit("dismiss")
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
    },
    async submitForm() {
      const isvalid = this.$refs.form.validate()
      if (isvalid) {
        this.loading = true

        try {
          let body = {}

          if (this.mode == "new") {
            body = {
              po_number: this.po_number,
              campaign_id: this.campaign?.id,
              customer_id: this.customer?.id,
              file: this.po_file,
            }
          } else {
            body = {
              id: this.purchaseOrder?.id,
              body: {
                po_number: this.po_number,
                campaign_id: this.campaign?.id,
                customer_id: this.customer?.id,
                file: this.po_file,
              },
            }
          }

          if (this.mode == "new") {
            await this.$store.dispatch('billing/createPurchaseOrder', body)
          } else {
            await this.$store.dispatch('billing/updatePurchaseOrder', body)
          }

          this.dismiss()

          this.getPurchaseOrders()

          this.$notify({
            title:
              this.mode == "new"
                ? "Purchase order created successfully!"
                : "Purchase order updated successfully!",
            group: "success",
          })
        } catch (error) {
          console.log(error)
          this.$notify({
            title: "An Error Occured, Please Try Again!",
            group: "errors",
          })
        } finally {
          this.loading = false
        }
      }
    },
  },
  computed: {
    __isDrawerOpen() {
      return this.formStatus
    },
    campaigns() {
      return this.$store.state.dsp.dsp_campaigns
    },
    customers() {
      return this.$store.state.billing.customers
    },
  },
}
</script>
