<template>
  <div>
    <POForm
      :formStatus="formStatus"
      @dismiss="dismissForm"
      :mode="mode"
      :purchaseOrder="purchase_order"
    ></POForm>

    <ConfirmDelete
      :dialog="confirmDeletePO"
      :item="selectedPo"
      @delete="deletePO($event)"
      @dismiss="dismissFn($event)"
    />

    <v-row>
      <v-col cols="12">
        <h3>Purchase Orders</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0" outlined>
          <v-card-title class="d-flex align-center justify-space-between">
            <div>
              <v-text-field
                outlined
                depressed
                dense
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </div>

            <div>
              <v-btn color="primary" elevation="0" @click.stop="createPO">
                <v-icon left dark> mdi-plus </v-icon> New Purchase Order</v-btn
              >
            </div>
          </v-card-title>

          <v-divider></v-divider>

          <v-data-table
            :headers="headers"
            :items="purchase_orders"
            :items-per-page="pagination.itemsPerPage"
            item-key="id"
            :loading="loading"
            hide-default-footer
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.purchase_order_id="{ item }">
              <h4 class="font-weight-medium">{{ item.purchase_order_id }}</h4>
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.campaign="{ item }">
              {{ item.campaign?.name }}
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.customer="{ item }">
              <v-row>
                <v-col cols="4"
                  ><h4 class="font-weight-medium">
                    {{ item.customer?.name }}
                  </h4></v-col
                >
                <v-col cols="8"
                  ><img :src="item.customer?.logo" height="15"
                /></v-col>
              </v-row>
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.po_file="{ item }">
              <a class="font-weight-bold" :href="item?.po_file" target="_blank"
                >PO File</a
              >
            </template>

            <!-- eslint-disable-next-line -->
            <template v-slot:item.date="{ item }">
              {{ item?.po_date }}
            </template>

            <!-- action -->
            <template #[`item.action`]="{ item }">
              <v-menu left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="editPO(item)">
                    <v-list-item-title
                      ><v-icon fab small class="me-2">mdi-pencil</v-icon>
                      Edit PO
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="copyBannerUrl(item)">
                    <v-list-item-title
                      ><v-icon fab small class="me-2">mdi-download</v-icon
                      >Download PO</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="setDeletePO(item)">
                    <v-list-item-title
                      ><v-icon small color="error" class="me-2"
                        >mdi-delete</v-icon
                      >
                      Delete PO</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </template> </v-data-table
          ><v-divider></v-divider>
          <v-card-actions class="pt-3">
            <div class="d-flex align-center">
              <div class="caption mx-3">
                Page: {{ pagination?.page }} of {{ pagination?.totalItems }}
              </div>

              <v-select
                v-model="pagination.per_page"
                :items="size"
                label="Rows Per Page"
                dense
                hide-details
              ></v-select>
            </div>

            <v-spacer></v-spacer>
            <v-pagination
              @input="setPageNumber"
              v-model="pagination.page"
              :length="pagination.totalItems"
              :total-visible="10"
              class="custom"
            ></v-pagination>
          </v-card-actions> </v-card
      ></v-col>
    </v-row>
  </div>
</template>

<script>
import POForm from "../../../components/Billing/POForm.vue"
import ConfirmDelete from "../../../components/ConfirmDelete.vue"
export default {
  components: {
    POForm,
    ConfirmDelete,
  },
  data() {
    return {
      search: "",
      loading: false,
      confirmDeletePO: false,
      selectedPo: null,
      headers: [
        {
          text: "PO Number",
          value: "po_number",
        },
        {
          text: "Campaign",
          value: "campaign",
        },
        {
          text: "Customer",
          value: "customer",
        },
        {
          text: "File",
          value: "po_file",
        },
        {
          text: "Date",
          value: "date",
        },
        {
          text: "Action",
          value: "action",
          align: "left",
          sortable: false,
        },
      ],
      menu: [
        { title: "Edit", icon: "mdi-download" },
        { title: "Download", icon: "mdi-content-copy" },
        { title: "Delete", icon: "mdi-delete" },
      ],
      size: ["10", "20", "50"],
      pagination: {
        page: 1,
        rowsPerPage: 10,
        totalItems: 0,
        itemsPerPage: 10,
      },
      formStatus: false,
      mode: "new",
      purchase_order: null,
    }
  },
  mounted() {
    this.getPurchaseOrders()
  },
  methods: {
    async getPurchaseOrders() {
      try {
        this.loading = true
        await this.$store.dispatch("billing/fetchPOs")
        this.loading = false
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
    createPO() {
      this.mode = "new"
      ;(this.purchase_order = null), (this.formStatus = !this.formStatus)
    },
    editPO(purchase_order) {
      this.mode = "edit"
      this.purchase_order = purchase_order
      this.formStatus = !this.formStatus
    },
    dismissForm() {
      this.formStatus = false
    },
    dismissFn() {
      this.confirmDeletePO = false
    },
    async setPageNumber(page) {
      this.pagination.page = page
    },
    setDeletePO(po) {
      this.confirmDeletePO = true
      this.selectedPo = po
    },
    async deletePO(id) {
      this.loading = true

      try {
        this.confirmDeletePO = false

        await this.$store.dispatch("billing/deletePurchaseOrder", id)

        this.getPurchaseOrders()

        this.$notify({
          title: "Purchase order deleted",
          group: "success",
        })
      } catch (error) {
        this.error = error
      } finally {
        this.loading = false
      }
    },
  },
  computed: {
    purchase_orders() {
      return this.$store.state.billing.purchase_orders
    },
  },
}
</script>
